import React, { useMemo } from "react";
import { Card } from "tabler-react";
import ReportChart from "./ReportChart";
import AccountingFormat from "../common/AccountingFormat";
import { useFlexLayout, useTable } from "react-table";
import { simulationPlanPdfOptions } from "../../../utils/pdfOptions";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";

const SimulationPlanPdf = ({
  organization,
  simulationPlan,
  baselinePlan,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
      width: 80,
      maxWidth: 250,
    }),
    []
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Beginning Balance",
        accessor: "beginningBalance",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.beginningBalance}</AccountingFormat>
        ),
      },
      {
        Header: "Available Investment",
        accessor: "availableInvestment",
        Cell: ({ row }) => (
          <AccountingFormat>
            {row.original.availableInvestment}
          </AccountingFormat>
        ),
      },
      {
        Header: "Interest Earned",
        accessor: "interestEarned",
      },
      {
        Header: "Annual Contribution",
        accessor: "collectionFees",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.collectionFees}</AccountingFormat>
        ),
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.total}</AccountingFormat>
        ),
      },
      {
        Header: "Expenses",
        accessor: "projectedExpenses",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.expenses}</AccountingFormat>
        ),
      },
      {
        Header: "Account Balance",
        accessor: "projectedBalance",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.accountBalance}</AccountingFormat>
        ),
      },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: simulationPlan, defaultColumn }, useFlexLayout);

  return (
    <div
      className="report-pdf"
      data-pdf-options={JSON.stringify(simulationPlanPdfOptions)}
    >
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            {organization.name}
            <br />
            <small className="text-muted-dark mt-1 mb-0">
              {organization.city}, {organization.state} {organization.zip}
            </small>
          </Card.Title>
          <div style={{ textAlign: "center" }}>
            <div>
              <Card.Title>Reserves Simulation Plan</Card.Title>
            </div>
            <div style={{ fontSize: "12px" }}>
              {`${organization.numberOfUnits} ${titleCase(
                abbrevMeasurementUnit(organization.measurementUnits)
              )}`}
            </div>
          </div>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <ReportChart
          className="d-flex justify-content-center my-3"
          chart1={{
            data: baselinePlan.map((row) => row.yearEndBalance),
            groupName: "baseline",
            displayName: "Baseline Account Balance",
            color: "blue",
          }}
          chart2={{
            data: simulationPlan.map((row) => row.accountBalance),
            groupName: "simulation",
            displayName: "Simulation Account Balance",
            color: "orange",
          }}
          years={simulationPlan.map((row) => row.year)}
        />
        <Card.Body>
          <div {...getTableProps()} className="table">
            <div className="thead">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps()}
                      className="th d-flex align-items-center justify-content-center text-center"
                    >
                      {column.render("Header")}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="tbody">
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          className="td d-flex align-items-center justify-content-center"
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SimulationPlanPdf;
