import React from "react";
import { Card, Grid } from "tabler-react";
import { changeLogTocPdfOptions } from "../../../utils/pdfOptions";

const ChangeLogReportToc = ({ webOnly, organization, generatedAt }) => {
  return (
    <div
      className={`change-log-pdf ${webOnly ? "web-only" : "pdf"}`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(changeLogTocPdfOptions)
      }
    >
      <Card>
        <Card.Body>
          <Grid.Row>
            <Grid.Col className="d-flex flex-column justify-content-center align-items-center">
              <h2 className="display-3 mb-3">Change Log</h2>
              <h3>{organization.name}</h3>
              <h5 className="text-muted">
                {organization.city}, {organization.state} {organization.zip}
              </h5>
              <h5 className="text-muted">Generated: {generatedAt}</h5>
              <h4 className="mt-5">Contents</h4>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#organization-change-log"
                >
                  Organization/Dashboard Change Log
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#reserve-items-change-log"
                >
                  Reserve Items Change Log
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#baseline-plan-settings-change-log"
                >
                  Baseline Plan Settings Change Log
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#simulation-plan-settings-change-log"
                >
                  Simulation Plan Settings Change Log
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#alternate-plan-settings-change-log"
                >
                  Alternate Plan Settings Change Log
                </a>
              </p>
              <p className="lead">
                <a
                  className="text-reset text-decoration-none text-dark"
                  href="#srr-settings-change-log"
                >
                  Special Req Report Change Log
                </a>
              </p>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ChangeLogReportToc;
