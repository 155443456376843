import React from "react";
import { Card, Table } from "tabler-react";
import { formatFundingSummaryRow } from "../../../utils/helpers";
import { fundingSummaryPdfOptions } from "../../../utils/pdfOptions";
import AccountingFormat from "../common/AccountingFormat";
import PercentFormat from "../common/PercentFormat";

const FundingSummaryPdf = ({
  color,
  organization,
  fundingSummary,
  webOnly,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");
  return (
    <div
      className={`funding-summary-pdf ${webOnly ? "web-only" : ""}`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(fundingSummaryPdfOptions)
      }
    >
      <Card statusColor={color}>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            {organization.name}
            <br />
            <small className="text-muted-dark mt-1 mb-0">
              {organization.city}, {organization.state} {organization.zip}
            </small>
          </Card.Title>
          <Card.Title>Funding Summary</Card.Title>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="table-responsive">
            <Table className="card-table table-vcenter mb-5">
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader>Topic</Table.ColHeader>
                  <Table.ColHeader>Baseline</Table.ColHeader>
                  <Table.ColHeader>Simulation</Table.ColHeader>
                  <Table.ColHeader>Alternate</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {fundingSummary.potentialInquiries.map((row, index) => (
                  <Table.Row key={`funding-summary-topic-year-${index}`}>
                    <Table.Col>{row.topic}</Table.Col>
                    <Table.Col className="text-center">
                      {formatFundingSummaryRow(
                        index,
                        row,
                        "baseline",
                        organization.measurementUnits
                      )}
                    </Table.Col>
                    <Table.Col className="text-center">
                      {formatFundingSummaryRow(
                        index,
                        row,
                        "simulation",
                        organization.measurementUnits
                      )}
                    </Table.Col>
                    <Table.Col className="text-center">
                      {formatFundingSummaryRow(
                        index,
                        row,
                        "alternate",
                        organization.measurementUnits
                      )}
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Table className="card-table table-vcenter">
              <Table.Header>
                <Table.Row className="text-center">
                  <Table.ColHeader></Table.ColHeader>
                  <Table.ColHeader colSpan={3}>Baseline</Table.ColHeader>
                  <Table.ColHeader colSpan={3}>Simulation</Table.ColHeader>
                  <Table.ColHeader colSpan={3}>Alternate</Table.ColHeader>
                </Table.Row>
                <Table.Row>
                  <Table.ColHeader>
                    Amt Req'd in Reserves Next 5 Yrs
                  </Table.ColHeader>
                  <Table.ColHeader>Year</Table.ColHeader>
                  <Table.ColHeader>Balance</Table.ColHeader>
                  <Table.ColHeader>Funded</Table.ColHeader>
                  <Table.ColHeader>Year</Table.ColHeader>
                  <Table.ColHeader>Balance</Table.ColHeader>
                  <Table.ColHeader>Funded</Table.ColHeader>
                  <Table.ColHeader>Year</Table.ColHeader>
                  <Table.ColHeader>Balance</Table.ColHeader>
                  <Table.ColHeader>Funded</Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {fundingSummary.amountRequired.map((row, index) => (
                  <Table.Row key={`amount-required-year-${index}`}>
                    <Table.Col>{row.title}</Table.Col>
                    <Table.Col className="text-center">
                      {row.baseline.year}
                    </Table.Col>
                    <Table.Col className="text-center">
                      <AccountingFormat>
                        {row.baseline.balance}
                      </AccountingFormat>
                    </Table.Col>
                    <Table.Col className="text-center">
                      <PercentFormat>{row.baseline.funded}</PercentFormat>
                    </Table.Col>
                    <Table.Col className="text-center">
                      {row.simulation.year}
                    </Table.Col>
                    <Table.Col className="text-center">
                      <AccountingFormat>
                        {row.simulation.balance}
                      </AccountingFormat>
                    </Table.Col>
                    <Table.Col className="text-center">
                      <PercentFormat>{row.simulation.funded}</PercentFormat>
                    </Table.Col>
                    <Table.Col className="text-center">
                      {row.alternate.year}
                    </Table.Col>
                    <Table.Col className="text-center">
                      <AccountingFormat>
                        {Math.round(row.alternate.balance)}
                      </AccountingFormat>
                    </Table.Col>
                    <Table.Col className="text-center">
                      <PercentFormat>{row.alternate.funded}</PercentFormat>
                    </Table.Col>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default FundingSummaryPdf;
