import React, { useMemo } from "react";
import { Card, colors, Table } from "tabler-react";
import capitalize from "capitalize";
import C3Chart from "react-c3js";
import AccountingFormat from "../common/AccountingFormat";
import { useFlexLayout, useTable } from "react-table";
import { percentFundedPdfOptions } from "../../../utils/pdfOptions";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";
import PercentFormat from "../common/PercentFormat";

const PercentFundedPdf = ({
  organization,
  percentFunded,
  reportType,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  const chartData = percentFunded.reduce(
    (memo, obj, index) => {
      if (index % 5 === 0) {
        memo.years.push(obj.year);
        memo.percentages.push(obj.percent);
      }
      return memo;
    },
    { years: [], percentages: [] }
  );
  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 20,
      width: 80,
      maxWidth: 250,
    }),
    []
  );

  const columns = useMemo(() => {
    return [
      {
        Header: "Year",
        accessor: "year",
      },
      {
        Header: "Accumulated Depreciation",
        accessor: "accumulatedDepreciation",
        Cell: ({ row }) => (
          <AccountingFormat>
            {row.original.accumulatedDepreciation}
          </AccountingFormat>
        ),
      },
      {
        Header: `${capitalize(reportType)} Balance`,
        accessor: "balance",
        Cell: ({ row }) => (
          <AccountingFormat>{row.original.balance}</AccountingFormat>
        ),
      },

      {
        Header: "Percent Funded",
        accessor: "percent",
        Cell: ({ row }) => (
          <PercentFormat>{row.original.percent}</PercentFormat>
        ),
      },
    ];
  }, [reportType]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: percentFunded, defaultColumn }, useFlexLayout);
  return (
    <div
      className="report-pdf"
      data-pdf-options={JSON.stringify({
        filenamePrefix: `${reportType}-percent-funded`,
        ...percentFundedPdfOptions,
      })}
    >
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            {organization.name}
            <br />
            <small className="text-muted-dark mt-1 mb-0">
              {organization.city}, {organization.state} {organization.zip}
            </small>
          </Card.Title>
          <div style={{ textAlign: "center" }}>
            <div>
              <Card.Title>{capitalize(reportType)} Percent Funded</Card.Title>
            </div>
            <div style={{ fontSize: "12px" }}>
              {`${organization.numberOfUnits} ${titleCase(
                abbrevMeasurementUnit(organization.measurementUnits)
              )}`}
            </div>
          </div>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <C3Chart
          className="mt-5 d-flex justify-content-center"
          style={{ height: "20rem" }}
          onrendered={() => {
            // Due to bugs in jsPDF and html2canvas, we need to manually set the font size for the labels,
            // adjust the y coordinates for the labels, and adjust some styles to get the 100%
            // line to show up correctly
            Array.from(
              document.querySelectorAll(
                ".report-pdf .c3-texts-percentFunded text"
              )
            ).forEach((el) => (el.style["font-size"] = "10px"));
            Array.from(
              document.querySelectorAll(
                ".report-pdf .c3-texts-percentFunded text"
              )
            ).forEach((el) => {
              const y = parseFloat(el.getAttribute("y"));
              const text = parseFloat(el.textContent);
              if (text < 0) el.setAttribute("y", y + 8);
            });
            Array.from(
              document.querySelectorAll(
                ".report-pdf .c3-ygrid-line.color-green line"
              )
            ).forEach((el) => {
              el.style["stroke"] = "#5eba00";
              el.style["stroke-width"] = "2px";
            });
            Array.from(
              document.querySelectorAll(
                ".report-pdf .c3-ygrid-line.color-gray line"
              )
            ).forEach((el) => {
              el.style["stroke"] = "#aaa";
              el.style["stroke-width"] = "1.5px";
            });
          }}
          data={{
            x: "years",
            columns: [
              ["years", ...chartData.years],
              ["percentFunded", ...chartData.percentages],
            ],
            colors: {
              percentFunded: ({ value }) =>
                value >= 0 ? colors["yellow"] : colors["red"],
            },
            types: {
              percentFunded: "bar",
            },
            names: { percentFunded: "Percent Funded" },
            labels: {
              show: true,
              format: (v) => `${(v * 100).toFixed(0)}%`,
            },
          }}
          legend={{ show: false }}
          point={{ show: false }}
          axis={{
            y: {
              max: 1,
              tick: {
                format: (v) => `${(v.toFixed(4) * 100).toFixed(0)}%`,
              },
            },
          }}
          grid={{
            y: {
              lines: [
                {
                  value: 0,
                  class: "color-gray",
                },
                {
                  value: 1,
                  class: "color-green",
                },
              ],
            },
          }}
          tooltip={{
            format: {
              value: (v) => `${(v * 100).toFixed(0)}%`,
            },
          }}
        />
        <Card.Body>
          <Table className="table-vcenter">
            <Table.Body>
              <Table.Row className="text-center">
                <Table.Col>
                  {"< 30% ="} <strong className="color-red">Weak</strong>
                </Table.Col>
                <Table.Col>
                  {"31 - 60% ="} <span className="color-red">Fair</span>
                </Table.Col>
                <Table.Col>
                  {"61 - 90% ="} <span className="color-green">Strong</span>
                </Table.Col>
                <Table.Col>
                  {"> 90% ="}{" "}
                  <strong className="color-green">Recommended</strong>
                </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
          <div {...getTableProps()} className="table">
            <div className="thead">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div
                      {...column.getHeaderProps()}
                      className="th d-flex align-items-center justify-content-center text-center"
                    >
                      {column.render("Header")}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div {...getTableBodyProps()} className="tbody">
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <div {...row.getRowProps()} className="tr">
                    {row.cells.map((cell) => {
                      return (
                        <div
                          {...cell.getCellProps()}
                          className="td d-flex align-items-center justify-content-center"
                        >
                          {cell.render("Cell")}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default PercentFundedPdf;
