import React from "react";
import { Card, Table } from "tabler-react";
import ReportChart from "./ReportChart";
import AccountingFormat from "../common/AccountingFormat";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";

const BaselinePlanTable = ({
  color,
  organization,
  plan,
  generatedAt,
  chartRef,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  return (
    <Card statusColor={color}>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title>
          {organization.name}
          <small className="text-muted-dark mt-1 mb-0">
            {organization.city}, {organization.state} {organization.zip}
          </small>
        </Card.Title>
        <div style={{ textAlign: "center" }}>
          <div>
            <Card.Title>Reserves Baseline Plan</Card.Title>
          </div>
          <div style={{ fontSize: "12px" }}>{`${
            organization.numberOfUnits
          } ${titleCase(
            abbrevMeasurementUnit(organization.measurementUnits)
          )}`}</div>
        </div>
        <Card.Title>Generated: {generatedAt}</Card.Title>
      </Card.Header>
      <ReportChart
        className="mt-5 d-flex justify-content-center"
        chart1={{
          data: plan.map((row) => row.yearEndBalance),
          groupName: "baseline",
          displayName: "Baseline Account Balance",
          color: "blue",
        }}
        years={plan.map((row) => row.year)}
        chartRef={chartRef}
      />
      <Card.Body>
        <div className="table-responsive">
          <Table className="card-table table-vcenter">
            <Table.Header>
              <Table.Row className="text-center">
                <Table.ColHeader>Year</Table.ColHeader>
                <Table.ColHeader>Beginning Balance</Table.ColHeader>
                <Table.ColHeader>Available Investment</Table.ColHeader>
                <Table.ColHeader>Interest Earned</Table.ColHeader>
                <Table.ColHeader>Annual Contribution</Table.ColHeader>
                <Table.ColHeader>Total</Table.ColHeader>
                <Table.ColHeader>Expenses</Table.ColHeader>
                <Table.ColHeader>Year End Balance</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {plan.map((row, index) => (
                <Table.Row
                  className="text-center"
                  key={`baseline-year-${index}`}
                >
                  <Table.Col>
                    <div>{row.year}</div>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.beginningBalance}</AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>
                      {row.availableInvestment}
                    </AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.interestEarned}</AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>
                      {row.annualContribution}
                    </AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.total}</AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.expenses}</AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.yearEndBalance}</AccountingFormat>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BaselinePlanTable;
