import React from "react";
import { specialRequirementReportPdfOptions } from "../../../utils/pdfOptions";
import { Card, Dimmer, Grid, Table } from "tabler-react";
import AccountingFormat from "../common/AccountingFormat";
import { titleCase } from "title-case";
import {
  abbrevMeasurementUnit,
  formatDecimalForUnits,
  formatPercent,
} from "../../../utils/helpers";
import SpecialRequirementReportForm from "./SpecialRequirementReportForm";
import { NumericFormat } from "react-number-format";
import PercentFormat from "../common/PercentFormat";

const SpecialRequirementReportPdf = ({
  color,
  organization,
  specialRequirementReport,
  reportLoading,
  webOnly,
  generatedAt,
  notEditable,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  return (
    <div
      className={`special-requirements-report-pdf ${
        webOnly ? "web-only" : "pdf"
      }`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(specialRequirementReportPdfOptions)
      }
    >
      <Card statusColor={color}>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            {organization.name}
            <br />
            <small className="text-muted-dark mt-1 mb-0">
              {organization.city}, {organization.state} {organization.zip}
            </small>
          </Card.Title>
          <Card.Title>Special Requirements Report</Card.Title>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row className="pdf-first-page-only">
            <Grid.Col>
              <SpecialRequirementReportForm
                percentIncrease={specialRequirementReport?.percentIncrease}
                webOnly={webOnly}
                notEditable={notEditable}
              />
            </Grid.Col>
            <Grid.Col>
              <Dimmer active={reportLoading} loader>
                <Card className="percent-increase mb-0">
                  <Card.Header>
                    <Card.Title>Required Annual Reserves Increase</Card.Title>
                  </Card.Header>
                  <Card.Body className="d-flex justify-content-center align-items-center">
                    {webOnly ? (
                      <h1>
                        {formatPercent(
                          specialRequirementReport.percentIncrease,
                          1
                        )}
                      </h1>
                    ) : (
                      <h3>
                        {formatPercent(
                          specialRequirementReport.percentIncrease,
                          1
                        )}
                      </h3>
                    )}
                  </Card.Body>
                </Card>
              </Dimmer>
            </Grid.Col>
          </Grid.Row>

          <div className="table-responsive">
            <Table className="card-table table-vcenter mb-5">
              <Table.Header>
                <Table.Row className="text-center">
                  <Table.ColHeader>Year</Table.ColHeader>
                  <Table.ColHeader>Beginning Balance</Table.ColHeader>
                  <Table.ColHeader>Annual Contribution</Table.ColHeader>
                  <Table.ColHeader>Interest Earned</Table.ColHeader>
                  <Table.ColHeader>Expenses</Table.ColHeader>
                  <Table.ColHeader>Year End Balance</Table.ColHeader>
                  <Table.ColHeader>Percent Funded</Table.ColHeader>
                  <Table.ColHeader>Assessment</Table.ColHeader>
                  <Table.ColHeader>
                    Cost per{" "}
                    {titleCase(
                      abbrevMeasurementUnit(organization.measurementUnits)
                    )}
                  </Table.ColHeader>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {specialRequirementReport.rows.map((row, index) => (
                  <Table.Row
                    key={`special-requirements-report-${index}`}
                    className="text-center"
                  >
                    <Table.Col>{row.year}</Table.Col>
                    <Table.Col>
                      <AccountingFormat>
                        {row.beginningBalance}
                      </AccountingFormat>
                    </Table.Col>
                    <Table.Col>
                      <AccountingFormat>
                        {row.annualContribution}
                      </AccountingFormat>
                    </Table.Col>
                    <Table.Col>
                      <AccountingFormat>{row.interestEarned}</AccountingFormat>
                    </Table.Col>
                    <Table.Col>
                      <AccountingFormat>{row.expenses}</AccountingFormat>
                    </Table.Col>
                    <Table.Col>
                      <AccountingFormat>{row.yearEndBalance}</AccountingFormat>
                    </Table.Col>
                    <Table.Col>
                      <PercentFormat>
                        {
                          specialRequirementReport.percentFundedRows[index]
                            .percent
                        }
                      </PercentFormat>
                    </Table.Col>
                    <Table.Col>
                      <AccountingFormat>{row.assessment}</AccountingFormat>
                    </Table.Col>
                    <Table.Col>
                      <NumericFormat
                        displayType="text"
                        prefix="$"
                        value={formatDecimalForUnits(
                          organization.measurementUnits,
                          row.costPerUnit
                        )}
                      />
                    </Table.Col>
                  </Table.Row>
                ))}
                {specialRequirementReport.isShortened && (
                  <Table.Col colSpan={9} className="text-center pt-5">
                    <strong>
                      <i>
                        Special Requirements Report is <u>shortened</u> since no
                        other years will have an assessment.
                      </i>
                    </strong>
                  </Table.Col>
                )}
              </Table.Body>
            </Table>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SpecialRequirementReportPdf;
