import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Alert, Button, Card, Form, Grid, Loader, Table } from "tabler-react";
import { useForm } from "react-hook-form";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  GET_ALTERNATE_PLAN_SETTING,
  GET_ANNUAL_REPORT,
  GET_BASELINE_PLAN_SETTING,
  GET_SIMULATION_PLAN_SETTING,
} from "../../../graphql/queries";
import UserContext from "../common/UserContext";
import LoadingPage from "../../../pages/LoadingPage";
import ErrorRedirect from "../../ErrorRedirect";
import capitalize from "capitalize";
import AccountingFormat from "../common/AccountingFormat";
import ReportsHelperMenu from "./ReportsHelperMenu";
import HoverReportPdf from "./HoverReportPdf";
import MissingPrerequisitesList from "./MissingPrerequisitesList";

const HoverReport = ({ defaultReportType, color }) => {
  const { user } = useContext(UserContext);
  const organization = user.focusedOrganization;
  const { register, errors, watch, getValues, setValue } = useForm();
  const watcher = watch(["reportType", "targetYear"]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    loading: alternateLoading,
    error: alternateError,
    data: alternateData,
  } = useQuery(GET_ALTERNATE_PLAN_SETTING, {
    skip: !organization,
    variables: {
      organizationId: organization.id,
      refreshCalculations: true,
    },
    fetchPolicy: "no-cache",
  });
  const {
    loading: baselineLoading,
    error: baselineError,
    data: baselineData,
  } = useQuery(GET_BASELINE_PLAN_SETTING, {
    skip: !organization,
    variables: {
      organizationId: organization.id,
      refreshCalculations: true,
    },
    fetchPolicy: "no-cache",
  });

  const {
    loading: simulationLoading,
    error: simulationError,
    data: simulationData,
  } = useQuery(GET_SIMULATION_PLAN_SETTING, {
    skip: !organization,
    variables: {
      organizationId: organization.id,
      refreshCalculations: true,
    },
    fetchPolicy: "no-cache",
  });
  const client = useApolloClient();

  const [targetYearOptions, setTargetYearOptions] = useState([]);
  const [hoverReportData, setHoverReportData] = useState();
  const categories =
    organization?.reportsCategories?.map((cat) =>
      cat.categoryType === "custom" ? "customCategory" : cat.name.toLowerCase()
    ) || [];

  const onSubmit = async () => {
    setIsSubmitting(true);
    let vals = getValues();
    let result = await client.query({
      query: GET_ANNUAL_REPORT,
      fetchPolicy: "no-cache",
      variables: {
        organizationId: vals.organizationId,
        reportType: vals.reportType,
      },
    });
    let targetYear = parseInt(vals.targetYear);
    let formatData = (memo, row, category, columnKey) => {
      memo[category][columnKey] = row[category];
      let catHoverData = row["hoverData"][category] || [];
      catHoverData.forEach((hd) => {
        memo[category]["hoverData"][hd.item] =
          memo[category]["hoverData"][hd.item] || {};
        memo[category]["hoverData"][hd.item][columnKey] = hd.cost;
      });
    };
    let data = result.data.annualReport.rows.reduce((memo, row) => {
      categories.forEach((category) => {
        memo[category] = memo[category] || {};
        memo[category]["hoverData"] = memo[category]["hoverData"] || {};
        if (row.year === targetYear - 1)
          formatData(memo, row, category, "priorYear");
        if (row.year === targetYear)
          formatData(memo, row, category, "targetYear");
        if (row.year === targetYear + 1)
          formatData(memo, row, category, "nextYear");
      });
      return memo;
    }, {});
    const hasCustomReportsCategory =
      !!result.data.annualReport.rows[0].customCategory;
    if (!hasCustomReportsCategory) delete data.customCategory;
    setHoverReportData(data);
    setIsSubmitting(false);
  };

  const onReportTypeChange = useCallback(
    (e) => {
      const generateRange = (start) =>
        Array.from({ length: 46 }, (_, i) => start + i);

      const val = e.target.value;
      setHoverReportData(null);
      if (val === "baseline")
        return setTargetYearOptions(
          generateRange(baselineData.baselinePlanSetting.beginningYear)
        );
      if (val === "alternate")
        return setTargetYearOptions(
          generateRange(alternateData.alternatePlanSetting.beginningYear)
        );
      if (val === "simulation")
        return setTargetYearOptions(
          generateRange(simulationData.simulationPlanSetting.beginningYear)
        );
    },
    [baselineData, alternateData, simulationData, setTargetYearOptions]
  );

  useEffect(() => {
    setValue("reportType", defaultReportType);
    if (!(baselineLoading || simulationLoading || alternateLoading)) {
      onReportTypeChange({ target: { value: defaultReportType } });
      setHoverReportData(null);
    }
  }, [
    baselineLoading,
    simulationLoading,
    alternateLoading,
    onReportTypeChange,
    defaultReportType,
    setValue,
    setHoverReportData,
  ]);

  const totals = useMemo(() => {
    if (hoverReportData)
      return Object.keys(hoverReportData).reduce((memo, category) => {
        ["priorYear", "targetYear", "nextYear"].forEach((yearKey) => {
          memo[yearKey] = memo[yearKey] || 0;
          memo[yearKey] += parseInt(hoverReportData[category][yearKey]);
        });
        return memo;
      }, {});
    return {};
  }, [hoverReportData]);

  const categoryName = useCallback(
    (name) => {
      if (name === "customCategory")
        return capitalize(
          organization?.reportsCategories.find(
            (cat) => cat.categoryType === "custom"
          ).name
        );
      return capitalize(name);
    },
    [organization]
  );

  // dont show anything if they are missing

  if (baselineLoading || simulationLoading || alternateLoading)
    return <LoadingPage />;
  if (baselineError || simulationError || alternateError)
    return (
      <ErrorRedirect
        error={baselineError || simulationError || alternateError}
      />
    );

  const missingPrereqs = [
    ...baselineData.baselinePlanSetting.missingPrerequisites,
    ...simulationData.simulationPlanSetting.missingPrerequisites,
    ...alternateData.alternatePlanSetting.missingPrerequisites,
  ];
  if (missingPrereqs.length)
    return (
      <Card statusColor={color} className="missing-prerequisites-card">
        <Card.Header>
          <Card.Title>Hover Report</Card.Title>
        </Card.Header>
        <Card.Body>
          <Alert type="danger">
            <p className="lead">
              In order to view the Hover Report, please complete the following
              steps:
            </p>
            <MissingPrerequisitesList missingPrerequisites={missingPrereqs} />
          </Alert>
        </Card.Body>
      </Card>
    );

  return (
    <div className="hover-report">
      <form>
        <input
          name="organizationId"
          type="hidden"
          ref={register}
          defaultValue={organization.id}
        />
        <Grid.Row className="justify-content-center">
          <Grid.Col width={3}>
            <Form.Group>
              <select
                onChange={onReportTypeChange}
                name="reportType"
                ref={register}
                defaultValue={defaultReportType}
                className={`custom-select form-select ${
                  errors.plan && "is-invalid"
                }`}
              >
                <option defaultValue="">Select a Plan to Utilize</option>
                <option value="baseline">Baseline Plan</option>
                <option value="simulation">Simulation Plan</option>
                <option value="alternate">Alternate Plan</option>
              </select>
              <div className="invalid-feedback">{errors.plan?.message}</div>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={3}>
            <Form.Group>
              <select
                name="targetYear"
                ref={register}
                onChange={() => setHoverReportData(null)}
                className={`custom-select form-select ${
                  errors.plan && "is-invalid"
                }`}
              >
                <option defaultValue="">Select a Target Year</option>
                {targetYearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <div className="invalid-feedback">
                {errors.targetYear?.message}
              </div>
            </Form.Group>
          </Grid.Col>
          <Grid.Col width={2}>
            <Button
              type="submit"
              color="primary"
              block={true}
              disabled={isSubmitting}
              onClick={onSubmit}
            >
              {isSubmitting ? (
                <Loader className="sm white center" />
              ) : (
                "Generate"
              )}
            </Button>
          </Grid.Col>
        </Grid.Row>
      </form>
      {hoverReportData && (
        <Grid.Row>
          <Grid.Col>
            <div className="helper-menu d-flex justify-content-end mt-0">
              <ReportsHelperMenu filenamePrefix="hover" />
            </div>
            <Card statusColor={color}>
              <Card.Header className="d-flex justify-content-between">
                <Card.Title>
                  {organization.name}
                  <br />
                  <small className="text-muted-dark mt-1 mb-0">
                    {organization.city}, {organization.state} {organization.zip}
                  </small>
                </Card.Title>
                <Card.Title>
                  <div className="text-center">
                    <div>Hover Report</div>
                    <div>
                      {capitalize(watcher.reportType)} - Year{" "}
                      {watcher.targetYear}
                    </div>
                  </div>
                </Card.Title>
                <Card.Title>
                  <div className="text-center">
                    <div>Generated</div>
                    <div>{new Date().toLocaleString("en-US")}</div>
                  </div>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-responsive">
                  <Table className="card-table table-vcenter">
                    <Table.Header>
                      <Table.Row className="text-center">
                        <Table.ColHeader></Table.ColHeader>
                        <Table.ColHeader>
                          Prior Year
                          <br />
                          <small>{parseInt(watcher.targetYear) - 1}</small>
                        </Table.ColHeader>
                        <Table.ColHeader>
                          <strong>
                            Target Year
                            <br />
                            <small>{watcher.targetYear}</small>
                          </strong>
                        </Table.ColHeader>
                        <Table.ColHeader>
                          Next Year
                          <br />
                          <small>{parseInt(watcher.targetYear) + 1}</small>
                        </Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {Object.keys(hoverReportData).map((category, index) => (
                        <Fragment key={`category-${index}`}>
                          <Table.Row>
                            <Table.Col className="bg-light">
                              <strong>{categoryName(category)}</strong>
                            </Table.Col>
                            <Table.Col className="bg-light text-center">
                              <AccountingFormat>
                                {parseInt(
                                  hoverReportData[category].priorYear || 0
                                )}
                              </AccountingFormat>
                            </Table.Col>
                            <Table.Col className="bg-light text-center">
                              <AccountingFormat>
                                {parseInt(
                                  hoverReportData[category].targetYear || 0
                                )}
                              </AccountingFormat>
                            </Table.Col>
                            <Table.Col className="bg-light text-center">
                              <AccountingFormat>
                                {parseInt(
                                  hoverReportData[category].nextYear || 0
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          {Object.keys(hoverReportData[category].hoverData).map(
                            (itemName, index) => (
                              <Table.Row key={`${itemName}-${index}`}>
                                <Table.Col className="text-right">
                                  {itemName}
                                </Table.Col>
                                <Table.Col className="text-center">
                                  <AccountingFormat>
                                    {parseInt(
                                      hoverReportData[category].hoverData[
                                        itemName
                                      ]?.priorYear || 0
                                    )}
                                  </AccountingFormat>
                                </Table.Col>
                                <Table.Col className="text-center">
                                  <AccountingFormat>
                                    {parseInt(
                                      hoverReportData[category].hoverData[
                                        itemName
                                      ]?.targetYear || 0
                                    )}
                                  </AccountingFormat>
                                </Table.Col>
                                <Table.Col className="text-center">
                                  <AccountingFormat>
                                    {parseInt(
                                      hoverReportData[category].hoverData[
                                        itemName
                                      ]?.nextYear || 0
                                    )}
                                  </AccountingFormat>
                                </Table.Col>
                              </Table.Row>
                            )
                          )}
                        </Fragment>
                      ))}
                      <Table.Row>
                        <Table.Col className="text-right">
                          <strong>Total</strong>
                        </Table.Col>
                        <Table.Col className="text-center">
                          <AccountingFormat>
                            {parseInt(totals.priorYear)}
                          </AccountingFormat>
                        </Table.Col>
                        <Table.Col className="text-center">
                          <AccountingFormat>
                            {parseInt(totals.targetYear)}
                          </AccountingFormat>
                        </Table.Col>
                        <Table.Col className="text-center">
                          <AccountingFormat>
                            {parseInt(totals.nextYear)}
                          </AccountingFormat>
                        </Table.Col>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </Card.Body>
            </Card>
            <div className="d-none">
              <HoverReportPdf
                hoverReportData={hoverReportData}
                targetYear={watcher.targetYear}
                reportType={watcher.reportType}
                organization={organization}
                totals={totals}
              />
            </div>
          </Grid.Col>
        </Grid.Row>
      )}
    </div>
  );
};

export default HoverReport;
