import html2pdf from "html2pdf.js";
import "./html2pdf-custom-pagebreak-settings";
import "./html2pdf-custom-graph-settings";
import CurrencyFormat from "../components/authenticated/common/CurrencyFormat";
import React from "react";
import { PDFDocument } from "pdf-lib";
import download from "downloadjs";
import * as XLSX from "xlsx";

export const partition = (array, isValid) => {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
};

export function range(start, end, increment = 1) {
  if (start >= end) return [start];
  return [start, ...range(start + increment, end, increment)];
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const findAndGenerateExcel = (filenamePrefix) => {
  let elements = Array.from(document.querySelectorAll("[data-excel-table]"));
  const wb = XLSX.utils.book_new();

  for (const element of elements) {
    let sheet = XLSX.utils.table_to_sheet(element);
    let sheetName = element.dataset.excelTable;
    XLSX.utils.book_append_sheet(wb, sheet, sheetName);
  }

  XLSX.writeFileXLSX(wb, `${filenamePrefix}.xlsx`);
};

export const findAndGeneratePdf = async (filenamePrefix, toPdfCallback) => {
  let elements = Array.from(document.querySelectorAll("[data-pdf-options]"));
  let orgLogoBase64 = document.querySelector("[data-organization-logo-base64]")
    ?.dataset?.organizationLogoBase64;

  // filter out nulls, empty strings, undefined(s), zeros etc.
  elements = elements.filter((element) => !!element);

  const mergedPdf = await PDFDocument.create();

  await sleep(100);

  for (const element of elements) {
    let options = JSON.parse(element.dataset.pdfOptions);
    let pdfBuffer = await html2pdf()
      .set(options)
      .from(element)
      .toPdf(orgLogoBase64)
      .outputPdf("arraybuffer");

    // intentionally slowing down so we dont crash their browser
    if (elements.length > 1) await sleep(1000);
    if (typeof toPdfCallback === "function") toPdfCallback();

    const pdf = await PDFDocument.load(pdfBuffer);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    for (const page of copiedPages) {
      mergedPdf.addPage(page);
    }
  }

  const mergedPdfFile = await mergedPdf.save();
  download(
    mergedPdfFile,
    `${filenamePrefix}-${new Date().toISOString().split("T")[0]}.pdf`,
    "application/pdf"
  );
};

export const toPercent = (num, fractionDigits = 0) =>
  `${(parseFloat(num) * 100).toFixed(fractionDigits)}%`;

export const formatDecimal = (num) => {
  const parsed = parseFloat(num);
  if (!parsed) return num;
  const absNum = Math.abs(parsed);
  if (absNum < 1000) return parsed.toFixed(2);
  return parsed.toFixed(0);
};

export const formatPercent = (value, fractionDigits = 0) => {
  const parsed = parseFloat(value);
  if (!parsed) return value;
  const absParsed = Math.abs(parsed);
  if (absParsed < 0.1) return toPercent(parsed, 2);
  return toPercent(parsed, fractionDigits);
};

export const formatCurrency = (value) => {
  return <CurrencyFormat>{value}</CurrencyFormat>;
};

export const formatPercentOrCurrency = (value, format) => {
  if (format === "percent") return formatPercent(value);
  if (format === "currency") return formatCurrency(formatDecimal(value));
  return value;
};

// truncates the decimal without rounding
export const truncDecimal = (num, digits) => {
  let regEx = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)");
  let match = num.toString().match(regEx);
  return match ? match[1] : num;
};

export const formatDecimalForUnits = (measurementUnits, num) => {
  if (num === 0 || num === "0") return num;
  if (!num) return;
  return measurementUnits === "square_feet"
    ? truncDecimal(num, 4)
    : formatDecimal(num);
};

export const abbrevMeasurementUnit = (measUnit, opts = {}) =>
  measUnit === "square_feet" ? "sq ft" : `door${opts.plural ? "s" : ""}`;

export const formatFundingSummaryRow = (
  index,
  row,
  column,
  measurementUnits
) => {
  if ([0, 5, 6].includes(index)) return row[column];
  if (index === 1)
    return formatCurrency(formatDecimalForUnits(measurementUnits, row[column]));

  return formatPercentOrCurrency(row[column], row.format);
};

export const findNullCols = (arr) => {
  if (!arr || arr.length === 0) return [];

  let keys = Object.keys(arr[0]);
  let nulls = keys.reduce((output, key) => {
    if (arr.every((item) => item[key] === null)) {
      output.push(key);
    }
    return output;
  }, []);
  return nulls;
};
