import React from "react";
import { Card, Table } from "tabler-react";
import capitalize from "capitalize";
import AccountingFormat from "../common/AccountingFormat";
import HoverDataPopover from "./HoverDataPopover";
import { titleCase } from "title-case";
import { abbrevMeasurementUnit } from "../../../utils/helpers";

const AnnualReportTable = ({
  color,
  organization,
  reportType,
  report,
  customReportsCategoryName,
  hasCustomReportsCategory,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  return (
    <Card statusColor={color}>
      <Card.Header className="d-flex justify-content-between">
        <Card.Title>
          {organization.name}
          <br />
          <small className="text-muted-dark mt-1 mb-0">
            {organization.city}, {organization.state} {organization.zip}
          </small>
        </Card.Title>
        <div style={{ textAlign: "center" }}>
          <div>
            <Card.Title>Annual Report - {capitalize(reportType)}</Card.Title>
          </div>
          <div style={{ fontSize: "12px" }}>
            {`${organization.numberOfUnits} ${titleCase(
              abbrevMeasurementUnit(organization.measurementUnits)
            )}`}
          </div>
        </div>
        <Card.Title>Generated: {generatedAt}</Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="table-responsive">
          <Table className="card-table table-vcenter">
            <Table.Header>
              <Table.Row className="text-center">
                <Table.ColHeader>Year</Table.ColHeader>
                <Table.ColHeader>Beginning Balance</Table.ColHeader>
                <Table.ColHeader>Annual Contribution</Table.ColHeader>
                <Table.ColHeader>Interest Earned</Table.ColHeader>
                <Table.ColHeader>Site</Table.ColHeader>
                <Table.ColHeader>Electrical</Table.ColHeader>
                <Table.ColHeader>Building</Table.ColHeader>
                <Table.ColHeader>Interior</Table.ColHeader>
                <Table.ColHeader>Mechanical</Table.ColHeader>
                {hasCustomReportsCategory && (
                  <Table.ColHeader>{customReportsCategoryName}</Table.ColHeader>
                )}
                <Table.ColHeader>Total Expenses</Table.ColHeader>
                <Table.ColHeader>Ending Balance</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {report.map((row, index) => (
                <Table.Row
                  className="text-center"
                  key={`${reportType}-annual-report-year-${index}`}
                >
                  <Table.Col>{row.year}</Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.beginningBalance}</AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>
                      {row.annualContribution}
                    </AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>{row.interestEarned}</AccountingFormat>
                  </Table.Col>
                  <Table.Col>
                    <HoverDataPopover
                      year={row.year}
                      hoverData={row.hoverData.site}
                    >
                      <AccountingFormat>{row.site}</AccountingFormat>
                    </HoverDataPopover>
                  </Table.Col>
                  <Table.Col>
                    <HoverDataPopover
                      year={row.year}
                      hoverData={row.hoverData.electrical}
                    >
                      <AccountingFormat>{row.electrical}</AccountingFormat>
                    </HoverDataPopover>
                  </Table.Col>
                  <Table.Col>
                    <HoverDataPopover
                      year={row.year}
                      hoverData={row.hoverData.building}
                    >
                      <AccountingFormat>{row.building}</AccountingFormat>
                    </HoverDataPopover>
                  </Table.Col>
                  <Table.Col>
                    <HoverDataPopover
                      year={row.year}
                      hoverData={row.hoverData.interior}
                    >
                      <AccountingFormat>{row.interior}</AccountingFormat>
                    </HoverDataPopover>
                  </Table.Col>
                  <Table.Col>
                    <HoverDataPopover
                      year={row.year}
                      hoverData={row.hoverData.mechanical}
                    >
                      <AccountingFormat>{row.mechanical}</AccountingFormat>
                    </HoverDataPopover>
                  </Table.Col>
                  {hasCustomReportsCategory && (
                    <Table.Col>
                      <HoverDataPopover
                        year={row.year}
                        hoverData={row.hoverData.customCategory}
                      >
                        <AccountingFormat>
                          {row.customCategory || 0}
                        </AccountingFormat>
                      </HoverDataPopover>
                    </Table.Col>
                  )}
                  <Table.Col>
                    <HoverDataPopover
                      year={row.year}
                      hoverData={row.hoverData.totalExpenses}
                    >
                      <AccountingFormat>{row.totalExpenses}</AccountingFormat>
                    </HoverDataPopover>
                  </Table.Col>
                  <Table.Col>
                    <AccountingFormat>
                      {row.cumulativeAccountBalance}
                    </AccountingFormat>
                  </Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};

export default AnnualReportTable;
