import React from "react";
import { Card, Grid, Table } from "tabler-react";
import { parmReportPdfOptions } from "../../../utils/pdfOptions";
import CurrencyFormat from "../common/CurrencyFormat";
import { titleCase } from "title-case";
import {
  abbrevMeasurementUnit,
  formatDecimal,
  formatDecimalForUnits,
  range,
  toPercent,
} from "../../../utils/helpers";

const ParmReportPdf = ({
  color,
  organization,
  parmReport,
  baselinePlanSetting,
  percentFunded,
  webOnly,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");
  return (
    <div
      className={`parm-report-pdf ${webOnly ? "web-only" : "pdf"}`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(parmReportPdfOptions)
      }
    >
      <Card statusColor={color}>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            {organization.name}
            <br />
            <small className="text-muted-dark mt-1 mb-0">
              {organization.city}, {organization.state} {organization.zip}
            </small>
          </Card.Title>
          <Card.Title>Pemaquid Aggressive Recovery Model Report</Card.Title>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Grid.Row className="mb-3">
            <Grid.Col width={8}>
              <Card className="sub-card">
                <Card.Header>
                  <Card.Title>Baseline Settings</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row className="mb-3">
                    <Grid.Col width={9}>Beginning Year</Grid.Col>
                    <Grid.Col>{baselinePlanSetting.beginningYear}</Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col width={9}>Beginning Balance</Grid.Col>
                    <Grid.Col>
                      <CurrencyFormat>
                        {baselinePlanSetting.beginningBalance}
                      </CurrencyFormat>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col width={9}>
                      Current Total Annual Reserves Funding
                    </Grid.Col>
                    <Grid.Col>
                      <CurrencyFormat>
                        {baselinePlanSetting.currentTotalAnnualReservesFunding}
                      </CurrencyFormat>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row className="mb-3">
                    <Grid.Col width={9}>
                      Current Monthly Contributions Per{" "}
                      {titleCase(
                        abbrevMeasurementUnit(organization.measurementUnits)
                      )}
                    </Grid.Col>
                    <Grid.Col>
                      <CurrencyFormat>
                        {formatDecimalForUnits(
                          organization.measurementUnits,
                          baselinePlanSetting.currentMonthlyHomeownerContributions
                        )}
                      </CurrencyFormat>
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Col width={9}>Percent Funded</Grid.Col>
                    <Grid.Col>{toPercent(percentFunded)}</Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col>
              <Card className="sub-card">
                <Card.Header>
                  <Card.Title>Recovery Model</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="table-responsive">
                    <Table className="card-table table-vcenter mb-5">
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader></Table.ColHeader>
                          {parmReport.rows.map((row, index) => (
                            <Table.ColHeader
                              className="text-center"
                              key={`parm-report-header-${index}`}
                            >
                              {row.year}
                            </Table.ColHeader>
                          ))}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Col>Recovery % Funded</Table.Col>
                          {parmReport.pfGoals.map((goal, index) => (
                            <Table.Col
                              className="text-center"
                              key={`pf-goals-${index}`}
                            >
                              {toPercent(goal)}
                            </Table.Col>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Col>Number of Payments</Table.Col>
                          {range(1, 5).map((index) => (
                            <Table.Col
                              className="text-center"
                              key={`number-of-payments-${index}`}
                            >
                              {index * 12}
                            </Table.Col>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Col>
                            Current Total Annual Reserves Funding
                          </Table.Col>
                          {range(1, 5).map((index) => (
                            <Table.Col
                              className="text-center"
                              key={`current-funding-${index}`}
                            >
                              <CurrencyFormat>
                                {
                                  baselinePlanSetting.currentTotalAnnualReservesFunding
                                }
                              </CurrencyFormat>
                            </Table.Col>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Col>PARM Total Annual Contribution</Table.Col>
                          {parmReport.rows.map((row, index) => (
                            <Table.Col
                              className="text-center"
                              key={`annual-amount-required-${index}`}
                            >
                              <CurrencyFormat>
                                {row.annualContributionNeeded}
                              </CurrencyFormat>
                            </Table.Col>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Col>PARM Monthly Contribution</Table.Col>
                          {parmReport.rows.map((row, index) => (
                            <Table.Col
                              className="text-center"
                              key={`monthly-contribution-per-${index}`}
                            >
                              <CurrencyFormat>
                                {row.monthlyContributionNeeded}
                              </CurrencyFormat>
                            </Table.Col>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Col>
                            PARM Monthly Cost Per{" "}
                            {titleCase(
                              abbrevMeasurementUnit(
                                organization.measurementUnits
                              )
                            )}
                          </Table.Col>
                          {parmReport.rows.map((row, index) => (
                            <Table.Col
                              className="text-center"
                              key={`monthly-cost-per-unit-${index}`}
                            >
                              <CurrencyFormat>
                                {formatDecimal(row.monthlyCostPerUnit)}
                              </CurrencyFormat>
                            </Table.Col>
                          ))}
                        </Table.Row>
                        <Table.Row>
                          <Table.Col>
                            PARM Percent Increase vs Current Cost Per{" "}
                            {titleCase(
                              abbrevMeasurementUnit(
                                organization.measurementUnits
                              )
                            )}
                          </Table.Col>
                          {parmReport.rows.map((row, index) => (
                            <Table.Col
                              className="text-center"
                              key={`monthly-cost-per-unit-${index}`}
                            >
                              {`${row.percentIncreaseVsCurrent.toFixed(0)}%`}
                            </Table.Col>
                          ))}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ParmReportPdf;
