import React, { Fragment } from "react";
import { Grid, Table } from "tabler-react";

const GlossaryContent = () => {
  return (
    <Fragment>
      <Grid.Row className="glossary">
        <Grid.Col>
          <Table striped hasOutline className="table-bordered">
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>Where Used</Table.ColHeader>
                <Table.ColHeader>Term</Table.ColHeader>
                <Table.ColHeader>Definition</Table.ColHeader>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Col>
                  <b>Reporting Categories</b>
                </Table.Col>
                <Table.Col>Site</Table.Col>
                <Table.Col>
                  Reserve items that are utilitarian in their broad use. Items
                  such as mailboxes, paving, sidewalks, swimming pools, fencing,
                  signs, parking areas, Picnic areas, Tennis courts, etc.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Electrical</Table.Col>
                <Table.Col>
                  All items that require electrical connectivity to function.
                  Items such as Light poles, generators, Electronic/Security
                  controls; circuit breaker boxes, Flashing/Warning Lights, Fire
                  Alarm devices, etc.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Building</Table.Col>
                <Table.Col>
                  Are items that are touched by the weather. Items such as
                  Siding, Roofing, Chimneys, Skylights, Solar Light tubes,
                  Dividers, Garage Doors, Front Porch, Patio, Patio Steps, etc.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Interior</Table.Col>
                <Table.Col>
                  Any items NOT touched by the weather. Items such as Flooring,
                  Carpeting, Furniture, Common Areas, Bathrooms, Kitchens, Bench
                  Seats, Hand Railing, Lobby PO Boxes, etc.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Mechanical</Table.Col>
                <Table.Col>
                  All items that monitor/provide services such as Elevators,
                  Cooling Towers, Pumps, Wells, Pump House, Chillers, Boilers,
                  Trash Compactors, Heat Exchangers, etc.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>6th Category</Table.Col>
                <Table.Col>
                  This is an editable field and can be updated when you decide
                  to create this incremental reporting category. Examples might
                  be Recreation, HVAC, Windows, Doors, etc.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>BLDG ID</Table.Col>
                <Table.Col>BLD</Table.Col>
                <Table.Col>
                  This is an incremental, optional field to apply if you desire
                  to delineate one building from another. As time passes,
                  sometimes this is a desired attribute. This is a 2 Character
                  Alpha/Numeric ID of your choice.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>Unit of Measure</Table.Col>
                <Table.Col>UoM</Table.Col>
                <Table.Col>
                  This provides further clarity when defining cost. There are 12
                  values in a drop- down box that you can apply. Values such as
                  Ton, Gallon, Each, Sq FT, etc.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <b>Financial Calculations</b>
                </Table.Col>
                <Table.Col>Available Investment</Table.Col>
                <Table.Col>
                  The ability to earn interest (Reserve Funds Invested) is not
                  calculated on every dollar. The observed practices are to hold
                  some level of funds in a liquid position (cash) to meet
                  potential short term/unplanned actions. To that end, the funds
                  available for Investment are planned in $25,000 increments.
                  For instance, if you have a total reserves balance of
                  $173,000, only $150,000 would have interest earned on it, and
                  so on.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Year Installed or Year Last Maintained</Table.Col>
                <Table.Col>
                  This calculation determines what funds are required to replace
                  each defined Reserve Item. In newer construction (the last 10
                  years) there would be limited replacements/items maintained.
                  In more established areas, built 40+ years ago, we do not need
                  to know the original installed date, but the date last
                  maintained and reset the required reserves accordingly.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Long-Term Inflation</Table.Col>
                <Table.Col>
                  U.S. long-term annual inflation for the past century
                  (1913-2015) is 3.22% according to InflationData.com. We use
                  this figure to calculate the current and future cost of
                  Reserve Items. As an example, let us assume that the current
                  year is 2020 and a certain reserve item was last installed in
                  2019 for a cost of $10,000. Our simulation would provide a
                  2020 cost of <b>$10,322</b>: $10,000 x (1 + 0.0322)
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Cash Flow, % Funded</Table.Col>
                <Table.Col>
                  <b>CAI & APRA</b> - a National Organization Association of
                  Professional Reserve Analysts. The APRA recommended standards
                  are defined for Analysts application. This includes the Cash
                  Flow Method and Percent (%) Funded calculation. This is a
                  group of 75+ Analysts in the United States. We follow these
                  standards.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Cash Flow, % Funded</Table.Col>
                <Table.Col>
                  <b>NRSS</b> - National Reserve Study Standards is a finance
                  guiding document supported and promoted by the Community
                  Associations Institute (CAI). This is a Board of 6 individuals
                  whose career experience is focused on providing 4 specific
                  measurements to determine strength of Reserve Study materials.
                  They mimic APRA in these 2 calculations
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Accumulated Depreciation</Table.Col>
                <Table.Col>
                  Depreciation is calculated different ways by different
                  companies depending on the use case. A vast majority of
                  companies (and organizations) use the straight- line method,
                  although some others use different methods which have
                  different assumptions. The double declining method is another
                  common one but does not make sense in the context of Reserve’s
                  Planning. The calculation of Accumulated Depreciation for the
                  purposes of measuring % Funded is as follows: <br />
                  <i>
                    Replacement Cost in Year X *(Year X – Install
                    Year)/Estimated Life
                  </i>
                  <br /> Then, compare that to actual Reserves Balance to get
                  the % Funded.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <b>Special Requirements Report</b>
                </Table.Col>
                <Table.Col>SRR</Table.Col>
                <Table.Col>
                  This <i>what if scenario</i> is designed to find the minimum %
                  increase in{" "}
                  <b>
                    <i>annual Reserve funding</i>
                  </b>{" "}
                  that would prevent the HOA from needing to collect a special
                  assessment greater than $X. (Enter desired threshold). So, if
                  the HOA says they are comfortable not to exceed a $25,000 for
                  special assessment, enter $25,000 into the field and the
                  application will define the percentage needed to make sure
                  that no assessments are larger than $25k and calculate annual
                  contribution required each successive year to achieve this.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  Pemaquid<sup>TM</sup> Aggressive Recovery Model
                </Table.Col>
                <Table.Col>PARM</Table.Col>
                <Table.Col>
                  The PARM what if scenario is created to respond to increasing
                  questions from Agencies, Insurance Carriers and like entities
                  that may guide their actions based on % Funded in your
                  Reserves. The PARM is designed to answer the question “What
                  would it take to be 100% Funded in 5 Years” and show the
                  calculations. The calculation takes the Baseline values, looks
                  out 5 years and provides the answer. This utilizes your
                  Dashboard Investment and inflation values as key components of
                  the calculation. This will calculate and create the report
                  with values at 60%, 70%, 80%, 90% and 100% time phased to
                  achieve the goal of 100% in 5 years and the incremental
                  suggested steps to succeed.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <b>License Administration</b>
                </Table.Col>
                <Table.Col>Invite Collaborators</Table.Col>
                <Table.Col>
                  Each license has an originator (
                  <u>
                    <b>Subscription Admin</b>
                  </u>
                  ) who purchased the license using their email address and
                  credit card. Then as the Subscription Admin you have the
                  opportunity to “invite” up to 4 others or not invite any, this
                  is an optional feature, not mandatory. When the invitation is
                  sent by the Subscription Admin, the Subscription Admin also
                  must select the type of access they desire as the Collaborator
                  to have. "
                  <u>
                    <b>Edit</b>
                  </u>
                  " allows the Collaborator to add/delete/update information in
                  the model, run reports, download PDF’s, etc. The "
                  <u>
                    <b>View Only</b>
                  </u>
                  " is just that, no other functionality is available.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <b>Funding Summary Report</b>
                </Table.Col>
                <Table.Col>Year Reserve Balance Updated</Table.Col>
                <Table.Col>
                  This is taken from the Finance Dashboard “Baseline” block.
                  This is the year you have entered that reflects the most
                  recent Reserve Account balance.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col></Table.Col>
                <Table.Col>Last Reserve Study (Year)</Table.Col>
                <Table.Col>
                  This calculation is taken to reflect, in aggregate, the
                  “average Year” of your ongoing enabled items cost updates.
                  This “year,” by design, is dynamic to reflect your ongoing
                  cost updates efforts.
                </Table.Col>
              </Table.Row>
              <Table.Row>
                <Table.Col>
                  <b>User Access</b>
                </Table.Col>
                <Table.Col>Concurrent</Table.Col>
                <Table.Col>
                  Pemaquid<sup>TM</sup> Reserves Planner provides for up to 5
                  “Users”, A Subscription Admin and up to 4 more. The access to
                  the PemaquidTM Reserves Planner is CONCURRENT. This means that
                  anyone of the invited members has access regardless of if any
                  others are “in the system” or not. This is a design decision
                  to provide maximum utilization. Users can have edit
                  capabilities or view only. There is a “Control Log” report
                  that captures all changes made by any authorized user. This is
                  by object username, date with the before and after value. As
                  an internal control device, we run a report two times/week
                  that provides an audit trail of any email access to each
                  environment. This states who logged in and will be utilized
                  should a basic question arise of “who was in there.” Finally,
                  if a user “forgets” to logout, the system has a “no activity
                  timer” on it and will automatically log out the user after 30
                  minutes of inactivity.
                </Table.Col>
              </Table.Row>
              <Table.Row className="no-stripe">
                <Table.Col className="no-stripe"></Table.Col>
              </Table.Row>
              <Table.Row className="no-stripe">
                <Table.Col className="no-stripe" colSpan={3}>
                  <p className="m-0">
                    <b>Note:</b> the <i>Reporting Categories</i> for assignment
                    to all Reserve items. There is a default already set up to
                    help you get started that follows this outline. If you want
                    to change the reporting category, go to the reserve item, go
                    to the category drop-down box, and change the term to the
                    desired one. Every Enabled Item needs a Report Category to
                    enable the downstream calculations.
                  </p>
                </Table.Col>
              </Table.Row>
              <Table.Row className="no-stripe">
                <Table.Col className="no-stripe" colSpan={3}>
                  <p className="m-0">
                    <b>Recommendation:</b> If you believe you need to create a
                    sixth term, before you add it, get your baseline done and
                    then decide the best TERM you want to use for the sixth
                    category. This one field is editable field.
                  </p>
                </Table.Col>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Col>
      </Grid.Row>
    </Fragment>
  );
};

export default GlossaryContent;
