import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { ApolloLink, InMemoryCache } from "@apollo/client";
import omitDeep from "omit-deep";
import { getMainDefinition } from "@apollo/client/utilities";
import Bugsnag from "@bugsnag/js";
import { toast } from "react-toastify";
import promiseToObservable from "./promiseToObservable";

class ApolloClientOptions {
  constructor({ user, token, setToken }) {
    this.user = user;
    this.token = token;
    this.setToken = setToken;
  }

  link() {
    const httpLink = createUploadLink({
      uri: `${process.env.REACT_APP_API_URL}/graphql`,
    });

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: this.token ? `Bearer ${this.token}` : "",
        },
      };
    });

    const onErrorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          const errorTypes = graphQLErrors.map((e) => e.extensions?.type);
          if (errorTypes.includes("UNAUTHENTICATED")) {
            // when logged in, retry api called EXCEPT the accept invitation one
            if (
              this.user &&
              operation?.operationName !== "AcceptInvitationAuthenticated"
            ) {
              //user was logged in, so get a new token and retry
              return promiseToObservable(this.user.getIdToken()).flatMap(
                (newToken) => {
                  const oldHeaders = operation.getContext().headers;
                  this.setToken(newToken);
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: newToken,
                    },
                  });
                  // Retry the request, returning the new observable
                  return forward(operation);
                }
              );
            } else {
              // user was not logged in, so ask them to log in.
              const messages = graphQLErrors.map((e) => e.message).join("\n");
              toast(messages, { toastId: "unauthenticated" });
            }
            // redirect to logout page
          } else if (errorTypes.includes("ACTIVATE_SUBSCRIPTION")) {
            toast("Please activate your subscription to continue.", {
              toastId: "activate-subscription",
            });
          } else if (errorTypes.includes("RENEW_SUBSCRIPTION")) {
            toast("Please renew your subscription to continue.", {
              toastId: "renew-subscription",
            });
          } else if (errorTypes.includes("UNAUTHORIZED")) {
            toast.error("You are not authorized to perform this action.", {
              toastId: "unauthorized",
            });
          } else {
            graphQLErrors.forEach((e) => {
              Bugsnag.notify(new Error(JSON.stringify(e)));
              if (
                process.env.NODE_ENV === "development" ||
                process.env.REACT_APP_TEST_ENV === "true"
              )
                console.error(`[GraphQLError] ${e.message}`, e);
            });
          }
        }
        if (networkError) {
          toast.error(
            "An unexpected connection error occurred. Please try refreshing your page."
          );
          Bugsnag.notify(new Error(JSON.stringify(networkError)));
          if (
            process.env.NODE_ENV === "development" ||
            process.env.REACT_APP_TEST_ENV === "true"
          )
            console.error(
              `[NetworkError] ${networkError.message}`,
              networkError
            );
        }
      }
    );

    const omitTypenameLink = new ApolloLink((operation, forward) => {
      const keysToOmit = ["__typename"];

      const def = getMainDefinition(operation.query);
      if (def && def.operation === "mutation") {
        operation.variables = omitDeep(operation.variables, keysToOmit);
      }
      return forward ? forward(operation) : null;
    });

    return onErrorLink
      .concat(authLink)
      .concat(omitTypenameLink)
      .concat(httpLink);
  }

  cache() {
    return new InMemoryCache();
  }
}

export default ApolloClientOptions;
