import React from "react";
import { Card, Grid } from "tabler-react";
import { photoAlbumPdfOptions } from "../../../utils/pdfOptions";
import { ReactComponent as NoImageAvailable } from "../../../no_image_available.svg";

const PhotoAlbumPdf = ({
  color,
  organization,
  reserveItems,
  webOnly,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size)
    );
  const items = chunk(reserveItems, 3);
  return (
    <div
      className={`photo-album-pdf ${webOnly ? "web-only" : "pdf-wrapper"}`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(photoAlbumPdfOptions)
      }
    >
      <Card statusColor={color}>
        <Card.Header className="d-flex justify-content-between">
          <Card.Title>
            {organization.name}
            <br />
            <small className="text-muted-dark mt-1 mb-0">
              {organization.city}, {organization.state} {organization.zip}
            </small>
          </Card.Title>
          <div style={{ textAlign: "center" }}>
            <div>
              <Card.Title>Photo Album</Card.Title>
            </div>
            <div
              style={{ fontSize: "12px" }}
            >{`${reserveItems.length} Enabled`}</div>
          </div>
          <Card.Title>Generated: {generatedAt}</Card.Title>
        </Card.Header>
        <Card.Body>
          {items.map((subList, rowIndex) => (
            <Grid.Row key={`row-${rowIndex}`}>
              {subList.map((reserveItem, colIndex) => (
                <Grid.Col key={`col-${colIndex}`} md={4}>
                  <Card className="photo">
                    <Card.Header className="d-flex justify-content-center">
                      <Card.Title>{reserveItem.itemName}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {reserveItem.photoBase64Preview ? (
                        <img
                          src={reserveItem.photoBase64Preview}
                          className="border rounded"
                          alt={reserveItem.itemName}
                        />
                      ) : (
                        <NoImageAvailable className="border rounded" />
                      )}
                    </Card.Body>
                  </Card>
                </Grid.Col>
              ))}
            </Grid.Row>
          ))}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PhotoAlbumPdf;
