import gql from "graphql-tag";

export const ErrorFragment = gql`
  fragment ErrorFragment on Error {
    message
    path
  }
`;
export const FailureReasonsFragment = gql`
  fragment FailureReasonsFragment on FailureReasons {
    details
    fullMessages
  }
`;

export const AuthorizationResultFragment = gql`
  fragment AuthorizationResultFragment on AuthorizationResult {
    value
    message
    reasons {
      ...FailureReasonsFragment
    }
  }
  ${FailureReasonsFragment}
`;

export const ReportsCategoryFragment = gql`
  fragment ReportsCategoryFragment on ReportsCategory {
    id
    name
    categoryType
  }
`;

export const ReserveItemFragment = gql`
  fragment ReserveItemFragment on ReserveItem {
    id
    itemName
    itemQuantity
    itemType
    yearsToReplace
    costPerItem
    buildingId
    unitOfMeasure
    lastInstall
    estimatedUsefulLife
    remainingUsefulLife
    latestCostYear
    notes
    totalCost
    enabled
    photoUrl
    photoBase64Preview
    documentUrl
    documentBase64Preview
    reportsCategory {
      ...ReportsCategoryFragment
    }
  }
  ${ReportsCategoryFragment}
`;

export const BaselinePlanReportRowFragment = gql`
  fragment BaselinePlanReportRowFragment on BaselinePlanReportRow {
    year
    beginningBalance
    availableInvestment
    annualContribution
    interestEarned
    total
    expenses
    yearEndBalance
  }
`;

export const BaselinePlanFragment = gql`
  fragment BaselinePlanFragment on BaselinePlan {
    rows {
      ...BaselinePlanReportRowFragment
    }
    missingPrerequisites
  }
  ${BaselinePlanReportRowFragment}
`;

export const BaselinePlanSettingFragment = gql`
  fragment BaselinePlanSettingFragment on BaselinePlanSetting {
    id
    beginningBalance
    beginningYear
    currentMonthlyHomeownerContributions
    currentTotalAnnualReservesFunding
    missingPrerequisites
  }
`;

export const SimulationPlanReportRowFragment = gql`
  fragment SimulationPlanReportRowFragment on SimulationPlanReportRow {
    year
    beginningBalance
    availableInvestment
    interestEarned
    collectionFees
    total
    expenses
    accountBalance
  }
`;

export const SimulationPlanFragment = gql`
  fragment SimulationPlanFragment on SimulationPlan {
    rows {
      ...SimulationPlanReportRowFragment
    }
    missingPrerequisites
  }
  ${SimulationPlanReportRowFragment}
`;

export const SimulationPlanSettingFragment = gql`
  fragment SimulationPlanSettingFragment on SimulationPlanSetting {
    id
    beginningBalance
    beginningYear
    desiredAdditionalAnnualFunds
    fundsCollectedInBeginningYear
    interestEarnedInBeginningYear
    interestRate
    monthlyFeeIncreasePerUnit
    missingPrerequisites
  }
`;

export const OrganizationFragment = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    city
    state
    zip
    measurementUnits
    numberOfUnits
    onboarded
    logoUrl
    remainingSeats
    logoBase64
    canAdmin {
      ...AuthorizationResultFragment
    }
    canEdit {
      ...AuthorizationResultFragment
    }
    interestEarnedOnReserves {
      id
      startYear
      endYear
      rate
    }
    inflationRatesOnItemCosts {
      id
      startYear
      endYear
      rate
    }
    reportsCategories {
      ...ReportsCategoryFragment
    }
  }
  ${ReportsCategoryFragment}
  ${AuthorizationResultFragment}
`;

export const SubscriptionFragment = gql`
  fragment SubscriptionFragment on Subscription {
    id
    status
    remainingSeats
    remainingLicenses
    canInviteUsers {
      ...AuthorizationResultFragment
    }
    canManageBilling {
      ...AuthorizationResultFragment
    }
  }
  ${AuthorizationResultFragment}
`;

export const InvitationFragment = gql`
  fragment InvitationFragment on Invitation {
    id
    email
    role
    status
    organizationName
    organizationId
  }
`;

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    email
    role
    subscription {
      ...SubscriptionFragment
    }
    focusedOrganization {
      ...OrganizationFragment
    }
    organizations {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragment}
  ${SubscriptionFragment}
`;

export const AnnualReportHoverDataItemFragment = gql`
  fragment AnnualReportHoverDataItemFragment on AnnualReportHoverDataItem {
    item
    cost
  }
`;

export const AnnualReportHoverDataFragment = gql`
  fragment AnnualReportHoverDataFragment on AnnualReportHoverData {
    site {
      ...AnnualReportHoverDataItemFragment
    }
    electrical {
      ...AnnualReportHoverDataItemFragment
    }
    building {
      ...AnnualReportHoverDataItemFragment
    }
    interior {
      ...AnnualReportHoverDataItemFragment
    }
    mechanical {
      ...AnnualReportHoverDataItemFragment
    }
    customCategory {
      ...AnnualReportHoverDataItemFragment
    }
    totalExpenses {
      ...AnnualReportHoverDataItemFragment
    }
  }
  ${AnnualReportHoverDataItemFragment}
`;

export const AnnualReportRowFragment = gql`
  fragment AnnualReportRowFragment on AnnualReportRow {
    year
    beginningBalance
    annualContribution
    interestEarned
    site
    electrical
    building
    interior
    mechanical
    customCategory
    totalExpenses
    cumulativeAccountBalance
    hoverData {
      ...AnnualReportHoverDataFragment
    }
  }
  ${AnnualReportHoverDataFragment}
`;

export const AnnualReportFragment = gql`
  fragment AnnualReportFragment on AnnualReport {
    rows {
      ...AnnualReportRowFragment
    }
    missingPrerequisites
  }
  ${AnnualReportRowFragment}
`;

export const PercentFundedRowFragment = gql`
  fragment PercentFundedRowFragment on PercentFundedRow {
    year
    accumulatedDepreciation
    balance
    percent
  }
`;

export const PercentFundedFragment = gql`
  fragment PercentFundedFragment on PercentFunded {
    rows {
      ...PercentFundedRowFragment
    }
    missingPrerequisites
  }
  ${PercentFundedRowFragment}
`;

export const AlternatePlanReportRowFragment = gql`
  fragment AlternatePlanReportRowFragment on AlternatePlanReportRow {
    year
    beginningBalance
    availableInvestment
    annualAmountRequired
    interest
    newBalance
    projectedExpenses
    projectedBalance
  }
`;

export const AlternatePlanFragment = gql`
  fragment AlternatePlanFragment on AlternatePlan {
    rows {
      ...AlternatePlanReportRowFragment
    }
    missingPrerequisites
  }
  ${AlternatePlanReportRowFragment}
`;

export const AlternatePlanSettingFragment = gql`
  fragment AlternatePlanSettingFragment on AlternatePlanSetting {
    id
    beginningBalance
    beginningYear
    interestRate
    term
    targetAmount
    projectedExpensesInTerm
    totalFundsRequired
    annualAmountRequired
    monthlyPerUnit
    monthlyAmountRequired
    missingPrerequisites
  }
`;

export const AcceleratorFragment = gql`
  fragment AcceleratorFragment on Accelerator {
    id
    name
    documentUrl
    extension
  }
`;

export const InflationCostsFragment = gql`
  fragment InflationCostsFragment on InflationCosts {
    headers
    values
    missingPrerequisites
  }
`;

export const FundingSummaryAmountRequiredSubRowFragment = gql`
  fragment FundingSummaryAmountRequiredSubRowFragment on FundingSummaryAmountRequiredSubRow {
    year
    balance
    funded
  }
`;

export const FundingSummaryAmountRequiredRowFragment = gql`
  fragment FundingSummaryAmountRequiredRowFragment on FundingSummaryAmountRequiredRow {
    title
    baseline {
      ...FundingSummaryAmountRequiredSubRowFragment
    }
    simulation {
      ...FundingSummaryAmountRequiredSubRowFragment
    }
    alternate {
      ...FundingSummaryAmountRequiredSubRowFragment
    }
  }
  ${FundingSummaryAmountRequiredSubRowFragment}
`;

export const FundingSummaryPotentialInquiriesRowFragment = gql`
  fragment FundingSummaryPotentialInquiriesRowFragment on FundingSummaryPotentialInquiriesRow {
    topic
    baseline
    simulation
    alternate
    format
  }
`;

export const FundingSummaryFragment = gql`
  fragment FundingSummaryFragment on FundingSummary {
    potentialInquiries {
      ...FundingSummaryPotentialInquiriesRowFragment
    }
    amountRequired {
      ...FundingSummaryAmountRequiredRowFragment
    }
    missingPrerequisites
  }
  ${FundingSummaryPotentialInquiriesRowFragment}
  ${FundingSummaryAmountRequiredRowFragment}
`;

export const PaymentMethodFragment = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    brand
    postalCode
    last4
    expMonth
    expYear
  }
`;

export const InvoicePreviewFragment = gql`
  fragment InvoicePreviewFragment on InvoicePreview {
    total
    licensePrice
    licenseQuantity
    maintenancePrice
    couponName
    couponPercentOff
  }
`;

export const PaidInvoiceFragment = gql`
  fragment PaidInvoiceFragment on PaidInvoice {
    number
    status
    hostedInvoiceUrl
    date
    amountDue
  }
`;

export const SubscriptionBillingDetailsFragment = gql`
  fragment SubscriptionBillingDetailsFragment on SubscriptionBillingDetails {
    startedAt
    currentPeriodEnd
    amount
    status
  }
`;

export const SpecialRequirementReportSettingFragment = gql`
  fragment SpecialRequirementReportSettingFragment on SpecialRequirementReportSetting {
    assessmentLimit
    percentIncrease
  }
`;

export const SpecialRequirementReportRowFragment = gql`
  fragment SpecialRequirementReportRowFragment on SpecialRequirementReportRow {
    year
    beginningBalance
    annualContribution
    interestEarned
    expenses
    yearEndBalance
    assessment
    costPerUnit
  }
`;

export const SpecialRequirementReportFragment = gql`
  fragment SpecialRequirementReportFragment on SpecialRequirementReport {
    percentIncrease
    isShortened
    rows {
      ...SpecialRequirementReportRowFragment
    }
    percentFundedRows {
      ...PercentFundedRowFragment
    }
    missingPrerequisites
  }
  ${SpecialRequirementReportRowFragment}
  ${PercentFundedRowFragment}
`;

export const RoleFragment = gql`
  fragment RoleFragment on Role {
    role
    description
  }
`;

export const ChangeLogReportRowFragment = gql`
  fragment ChangeLogReportRowFragment on ChangeLogReportRow {
    name
    whodunnit
    objectChanges
    createdAt
  }
`;

export const ChangeLogReportFragment = gql`
  fragment ChangeLogReportFragment on ChangeLogReport {
    alternatePlanSettingVersions {
      ...ChangeLogReportRowFragment
    }
    baselinePlanSettingVersions {
      ...ChangeLogReportRowFragment
    }
    organizationVersions {
      ...ChangeLogReportRowFragment
    }
    reserveItemVersions {
      ...ChangeLogReportRowFragment
    }
    simulationPlanSettingVersions {
      ...ChangeLogReportRowFragment
    }
    specialRequirementReportSettingVersions {
      ...ChangeLogReportRowFragment
    }
  }
  ${ChangeLogReportRowFragment}
`;

export const ParmReportRowFragment = gql`
  fragment ParmReportRowFragment on ParmReportRow {
    year
    annualContributionNeeded
    monthlyContributionNeeded
    monthlyCostPerUnit
    percentIncreaseVsCurrent
  }
`;

export const ParmReportFragment = gql`
  fragment ParmReportFragment on ParmReport {
    rows {
      ...ParmReportRowFragment
    }
    pfGoals
    missingPrerequisites
  }
  ${ParmReportRowFragment}
`;

export const TheBookFragment = gql`
  fragment TheBookFragment on TheBook {
    baselinePlanSetting {
      ...BaselinePlanSettingFragment
    }
    simulationPlanSetting {
      ...SimulationPlanSettingFragment
    }
    alternatePlanSetting {
      ...AlternatePlanSettingFragment
    }
    baselinePlan {
      ...BaselinePlanFragment
    }
    baselineAnnualReport {
      ...AnnualReportFragment
    }
    baselinePercentFunded {
      ...PercentFundedFragment
    }
    alternatePlan {
      ...AlternatePlanFragment
    }
    alternateAnnualReport {
      ...AnnualReportFragment
    }
    alternatePercentFunded {
      ...PercentFundedFragment
    }
    simulationPlan {
      ...SimulationPlanFragment
    }
    simulationAnnualReport {
      ...AnnualReportFragment
    }
    simulationPercentFunded {
      ...PercentFundedFragment
    }
    fundingSummary {
      ...FundingSummaryFragment
    }
    specialRequirementsReport {
      ...SpecialRequirementReportFragment
    }
    parmReport {
      ...ParmReportFragment
    }
    missingPrerequisites
  }
  ${BaselinePlanSettingFragment}
  ${SimulationPlanSettingFragment}
  ${AlternatePlanSettingFragment}
  ${BaselinePlanFragment}
  ${AnnualReportFragment}
  ${PercentFundedFragment}
  ${SimulationPlanFragment}
  ${AlternatePlanFragment}
  ${FundingSummaryFragment}
  ${SpecialRequirementReportFragment}
  ${ParmReportFragment}
`;

export const CollaboratorFragment = gql`
  fragment CollaboratorFragment on Collaborator {
    email
    role
    status
    invitationId
  }
`;

export const UsStateFragment = gql`
  fragment UsStateFragment on UsState {
    shortName
    name
  }
`;
