import React from "react";
import { Card, Grid, Table } from "tabler-react";
import AccountingFormat from "../common/AccountingFormat";
import { financialDashboardPdfOptions } from "../../../utils/pdfOptions";
import {
  abbrevMeasurementUnit,
  formatDecimal,
  formatDecimalForUnits,
  range,
} from "../../../utils/helpers";
import { titleCase } from "title-case";

const FinancialDashboardPdf = ({
  organization,
  baselinePlanSetting,
  simulationPlanSetting,
  alternatePlanSetting,
  webOnly,
  generatedAt,
}) => {
  generatedAt = generatedAt || new Date().toLocaleString("en-US");
  const sqFtRange = () => [
    ...range(1000, 2400, 100),
    ...range(2600, 3000, 200),
    ...range(3500, 4000, 500),
  ];
  const round = (num) => num.toFixed(2).replace(/\.00$/, "");

  return (
    <div
      className={`financial-dashboard-pdf ${webOnly ? "web-only" : ""}`}
      data-pdf-options={
        webOnly ? undefined : JSON.stringify(financialDashboardPdfOptions)
      }
    >
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <Card.Title>
            {organization.name}
            <br />
            <small className="text-muted-dark mt-1 mb-0">
              {organization.city}, {organization.state} {organization.zip}
            </small>
          </Card.Title>
          <Card.Title>
            <div>Financial Dashboard</div>
            <div>
              <small className="text-muted m-0 text-center">
                {organization.numberOfUnits}{" "}
                {titleCase(
                  abbrevMeasurementUnit(organization.measurementUnits, {
                    plural: true,
                  })
                )}
              </small>
            </div>
          </Card.Title>
          <Card.Title className="small-title">
            Generated: {generatedAt}
          </Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Grid.Row>
            <Grid.Col width={6}>
              <Grid.Row>
                <Grid.Col>
                  <Card className="baseline-plan-settings sub-card mb-0">
                    <Card.Header>
                      <Card.Title>Baseline Settings</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Table data-excel-table="Organization" className="d-none">
                        <Table.Body>
                          <Table.Row>
                            <Table.Col>
                              {`${organization.name} ${
                                organization.numberOfUnits
                              } ${titleCase(
                                abbrevMeasurementUnit(
                                  organization.measurementUnits,
                                  {
                                    plural: true,
                                  }
                                )
                              )}`}
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              {organization.city}, {organization.state}{" "}
                              {organization.zip}
                            </Table.Col>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                      <Table
                        data-excel-table="Baseline Settings"
                        className="card-table table-vcenter text-nowrap"
                      >
                        <Table.Body>
                          <Table.Row>
                            <Table.Col>Beginning Year</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              {baselinePlanSetting.beginningYear}
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Beginning Balance</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {baselinePlanSetting.beginningBalance}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              Current Total Annual Reserves Funding
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end">
                              <AccountingFormat>
                                {
                                  baselinePlanSetting.currentTotalAnnualReservesFunding
                                }
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              Current Monthly Contributions Per{" "}
                              {abbrevMeasurementUnit(
                                organization.measurementUnits
                              )}
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end">
                              <AccountingFormat>
                                {formatDecimalForUnits(
                                  organization.measurementUnits,
                                  baselinePlanSetting.currentMonthlyHomeownerContributions
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row>
                <Grid.Col>
                  <Card className="simulation-plan-settings sub-card mb-0">
                    <Card.Header>
                      <Card.Title>Simulation Settings</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Grid.Row>
                        <Grid.Col>
                          <Table
                            data-excel-table="Simulation Settings"
                            className="card-table table-vcenter text-nowrap"
                          >
                            <Table.Body>
                              <Table.Row>
                                <Table.Col>Beginning Year</Table.Col>
                                <Table.Col className="d-flex justify-content-end align-items-center">
                                  {simulationPlanSetting.beginningYear}
                                </Table.Col>
                              </Table.Row>
                              <Table.Row>
                                <Table.Col>Beginning Balance</Table.Col>
                                <Table.Col className="d-flex justify-content-end align-items-center">
                                  <AccountingFormat>
                                    {simulationPlanSetting.beginningBalance}
                                  </AccountingFormat>
                                </Table.Col>
                              </Table.Row>
                              <Table.Row>
                                <Table.Col>
                                  Approved Budget this Period
                                </Table.Col>
                                <Table.Col className="d-flex justify-content-end align-items-center">
                                  <AccountingFormat>
                                    {
                                      simulationPlanSetting.fundsCollectedInBeginningYear
                                    }
                                  </AccountingFormat>
                                </Table.Col>
                              </Table.Row>
                              <Table.Row>
                                <Table.Col>
                                  "What if" Simulation Reserves Fund Increase
                                </Table.Col>
                                <Table.Col className="d-flex justify-content-end align-items-center">
                                  <AccountingFormat>
                                    {
                                      simulationPlanSetting.desiredAdditionalAnnualFunds
                                    }
                                  </AccountingFormat>
                                </Table.Col>
                              </Table.Row>
                              <Table.Row>
                                <Table.Col>
                                  Monthly Fee Increase per{" "}
                                  {titleCase(
                                    abbrevMeasurementUnit(
                                      organization.measurementUnits
                                    )
                                  )}
                                </Table.Col>
                                <Table.Col className="d-flex justify-content-end align-items-center">
                                  <AccountingFormat>
                                    {formatDecimalForUnits(
                                      organization.measurementUnits,
                                      simulationPlanSetting.monthlyFeeIncreasePerUnit
                                    )}
                                  </AccountingFormat>
                                </Table.Col>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Grid.Col>
                      </Grid.Row>
                    </Card.Body>
                  </Card>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
            <Grid.Col width={6}>
              <Card className="alternate-plan-settings sub-card mb-0">
                <Card.Header>
                  <Card.Title>Alternate Settings</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col>
                      <Table
                        data-excel-table="Alternate Settings"
                        className="card-table table-vcenter text-nowrap"
                      >
                        <Table.Body>
                          <Table.Row>
                            <Table.Col>Beginning Year</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              {alternatePlanSetting.beginningYear}
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Beginning Balance</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {alternatePlanSetting.beginningBalance}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Term (Years)</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              {alternatePlanSetting.term}
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Target Amount</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {alternatePlanSetting.targetAmount}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Projected Expenses in Term</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {formatDecimal(
                                  alternatePlanSetting.projectedExpensesInTerm
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Total Funds Required</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {formatDecimal(
                                  alternatePlanSetting.totalFundsRequired
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Annual Amount Required</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {formatDecimal(
                                  alternatePlanSetting.annualAmountRequired
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>Monthly Amount Required</Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {formatDecimal(
                                  alternatePlanSetting.monthlyAmountRequired
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                          <Table.Row>
                            <Table.Col>
                              Monthly Per{" "}
                              {titleCase(
                                abbrevMeasurementUnit(
                                  organization.measurementUnits
                                )
                              )}
                            </Table.Col>
                            <Table.Col className="d-flex justify-content-end align-items-center">
                              <AccountingFormat>
                                {formatDecimalForUnits(
                                  organization.measurementUnits,
                                  alternatePlanSetting.monthlyPerUnit
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col width={6}>
              <Card className="sub-card mb-0">
                <Card.Header className="d-flex justify-content-center">
                  <Card.Title>Interest Earned on Reserves</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table
                    data-excel-table="Interest Earned on Reserves"
                    className="card-table table-vcenter"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader className="text-center">
                          Start Year Offset
                        </Table.ColHeader>
                        <Table.ColHeader className="text-center">
                          End Year Offset
                        </Table.ColHeader>
                        <Table.ColHeader className="text-center">
                          Interest Rate
                        </Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {organization.interestEarnedOnReserves.map(
                        (item, index) => (
                          <Table.Row
                            key={`interestEarnedOnReserves-table-row-${index}`}
                          >
                            <Table.Col className="text-center">
                              {item.startYear}
                            </Table.Col>
                            <Table.Col className="text-center">
                              {item.endYear}
                            </Table.Col>
                            <Table.Col className="text-center">
                              {item.rate}%
                            </Table.Col>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Card.Body>
              </Card>
            </Grid.Col>
            <Grid.Col width={6}>
              <Card className="sub-card mb-0">
                <Card.Header className="d-flex justify-content-center">
                  <Card.Title>Future Inflation Rates on Item Costs</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table
                    data-excel-table="Infl Rates on Item Costs"
                    className="card-table table-vcenter"
                  >
                    <Table.Header>
                      <Table.Row>
                        <Table.ColHeader className="text-center">
                          Start Year Offset
                        </Table.ColHeader>
                        <Table.ColHeader className="text-center">
                          End Year Offset
                        </Table.ColHeader>
                        <Table.ColHeader className="text-center">
                          Inflation Rate
                        </Table.ColHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {organization.inflationRatesOnItemCosts.map(
                        (item, index) => (
                          <Table.Row
                            key={`inflationRatesOnItemCosts-table-row-${index}`}
                          >
                            <Table.Col className="text-center">
                              {item.startYear}
                            </Table.Col>
                            <Table.Col className="text-center">
                              {item.endYear}
                            </Table.Col>
                            <Table.Col className="text-center">
                              {item.rate}%
                            </Table.Col>
                          </Table.Row>
                        )
                      )}
                    </Table.Body>
                  </Table>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col className="d-flex justify-content-end">
              <small className="text-muted inflation-rate">
                Inflation Rate = 3.22% for all years before Future Inflation
                Forecast
              </small>
            </Grid.Col>
          </Grid.Row>
          {organization.measurementUnits === "square_feet" && (
            <Grid.Row>
              <Grid.Col>
                <Card className="sub-card mb-0">
                  <Card.Header className="d-flex justify-content-center border-0">
                    <Card.Title>Baseline Fee Schedule</Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Table className="card-table table-vcenter">
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            If Your
                          </Table.ColHeader>
                          <Table.ColHeader
                            colSpan={2}
                            className="text-center pt-1 pb-1"
                          >
                            Your Projected Cost
                          </Table.ColHeader>
                        </Table.Row>
                        <Table.Row>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            Unit Sq Ft is
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            Annually
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center pt-1 pb-1">
                            Monthly
                          </Table.ColHeader>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {sqFtRange().map((sqFt) => (
                          <Table.Row key={`baseline-sqft-table-row-${sqFt}`}>
                            <Table.Col className="text-center border-right">
                              {sqFt}
                            </Table.Col>
                            <Table.Col className="text-center border-right">
                              <AccountingFormat>
                                {round(
                                  sqFt *
                                    baselinePlanSetting.currentMonthlyHomeownerContributions *
                                    12
                                )}
                              </AccountingFormat>
                            </Table.Col>
                            <Table.Col className="text-center">
                              <AccountingFormat>
                                {round(
                                  sqFt *
                                    baselinePlanSetting.currentMonthlyHomeownerContributions
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col>
                <Card className="sub-card mb-0">
                  <Card.Header className="d-flex justify-content-center border-0">
                    <Card.Title>Simulation Fee Schedule</Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Table className="card-table table-vcenter">
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            If Your
                          </Table.ColHeader>
                          <Table.ColHeader
                            colSpan={2}
                            className="text-center pt-1 pb-1"
                          >
                            Your Projected Cost
                          </Table.ColHeader>
                        </Table.Row>
                        <Table.Row>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            Unit Sq Ft is
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            Annually
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center pt-1 pb-1">
                            Monthly
                          </Table.ColHeader>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {sqFtRange().map((sqFt) => (
                          <Table.Row key={`simulation-sqft-table-row-${sqFt}`}>
                            <Table.Col className="text-center border-right">
                              {sqFt}
                            </Table.Col>
                            <Table.Col className="text-center border-right">
                              <AccountingFormat>
                                {round(
                                  sqFt *
                                    (baselinePlanSetting.currentMonthlyHomeownerContributions +
                                      simulationPlanSetting.monthlyFeeIncreasePerUnit) *
                                    12
                                )}
                              </AccountingFormat>
                            </Table.Col>
                            <Table.Col className="text-center">
                              <AccountingFormat>
                                {round(
                                  sqFt *
                                    (baselinePlanSetting.currentMonthlyHomeownerContributions +
                                      simulationPlanSetting.monthlyFeeIncreasePerUnit)
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Card.Body>
                </Card>
              </Grid.Col>
              <Grid.Col>
                <Card className="sub-card mb-0">
                  <Card.Header className="d-flex justify-content-center border-0">
                    <Card.Title>Alternate Fee Schedule</Card.Title>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <Table className="card-table table-vcenter">
                      <Table.Header>
                        <Table.Row>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            If Your
                          </Table.ColHeader>
                          <Table.ColHeader
                            colSpan={2}
                            className="text-center pt-1 pb-1"
                          >
                            Your Projected Cost
                          </Table.ColHeader>
                        </Table.Row>
                        <Table.Row>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            Unit Sq Ft is
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center pt-1 pb-1 border-right">
                            Annually
                          </Table.ColHeader>
                          <Table.ColHeader className="text-center pt-1 pb-1">
                            Monthly
                          </Table.ColHeader>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {sqFtRange().map((sqFt) => (
                          <Table.Row key={`alternate-sqft-table-row-${sqFt}`}>
                            <Table.Col className="text-center border-right">
                              {sqFt}
                            </Table.Col>
                            <Table.Col className="text-center border-right">
                              <AccountingFormat>
                                {round(
                                  sqFt *
                                    alternatePlanSetting.monthlyPerUnit *
                                    12
                                )}
                              </AccountingFormat>
                            </Table.Col>
                            <Table.Col className="text-center">
                              <AccountingFormat>
                                {round(
                                  sqFt * alternatePlanSetting.monthlyPerUnit
                                )}
                              </AccountingFormat>
                            </Table.Col>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default FinancialDashboardPdf;
