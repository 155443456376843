import React, { useMemo, useRef } from "react";
import { reactFormatter, ReactTabulator } from "react-tabulator";
import { useApolloClient, useMutation } from "@apollo/client";
import { UPDATE_RESERVE_ITEM } from "../../../graphql/mutations";
import { ReserveItemFragment } from "../../../graphql/fragments";
import { GET_ALTERNATE_PLAN_SETTING } from "../../../graphql/queries";
import ReserveItemPhotoUpload from "./ReserveItemPhotoUpload";
import ReserveItemDocumentUpload from "./ReserveItemDocumentUpload";
import customReactFormatter from "../../../utils/customReactFormatter";
import { toast } from "react-toastify";
import customMoneyFormatter from "../../../utils/customMoneyFormatter";
import customNumberEditor from "../../../utils/customNumberEditor";
import ReserveItemEnabledCell from "./ReserveItemEnabledCell";

const ReserveItemsTable = ({
  showingTab,
  organization,
  reserveItems,
  setIsSaving,
  canEdit,
}) => {
  const client = useApolloClient();

  // Need useMemo here to prevent the scroll from jumping when toggling enabled/disabled switches
  const filteredReserveItems = useMemo(() => {
    if (reserveItems)
      return reserveItems.filter(
        (e) => e.enabled === (showingTab === "enabled")
      );
    return [];
    // eslint-disable-next-line
  }, [reserveItems, showingTab]);

  const [updateReserveItem] = useMutation(UPDATE_RESERVE_ITEM, {
    update(
      cache,
      {
        data: {
          updateReserveItem: { reserveItem },
        },
      }
    ) {
      if (reserveItem) {
        cache.writeFragment({
          id: reserveItem.id,
          fragment: ReserveItemFragment,
          data: reserveItem,
          fragmentName: "ReserveItemFragment",
        });
      }
    },
    refetchQueries: [
      {
        query: GET_ALTERNATE_PLAN_SETTING,
        variables: {
          organizationId: organization?.id,
          refreshCalculations: true,
        },
      },
    ],
  });

  const reportCategoryIdToNameMap = organization.reportsCategories.reduce(
    (memo, category) => {
      memo[category.id] = category.name;
      return memo;
    },
    {}
  );

  const withPlaceholder = (placeholder) => {
    return (cell) => {
      const cellValue = cell.getValue();
      if (cellValue === "" || cellValue === null)
        return `<div class="placeholder">${placeholder}</div>`;
      return cellValue;
    };
  };

  const rulFormatter = (num) => {
    return (cell) => {
      const cellValue = cell.getValue();
      if (Number.isInteger(cellValue) && cellValue < 0)
        return `<span class="text-danger">${cellValue}</span>`;
      return cellValue;
    };
  };

  const columnsRef = useRef([
    {
      title: "Enabled",
      field: "enabled",
      formatter: reactFormatter(<ReserveItemEnabledCell canEdit={canEdit} />),
      hozAlign: "center",
      headerHozAlign: "center",
      resizable: false,
      headerSort: false,
      frozen: true,
      tooltip: false,
      width: 70,
    },
    {
      title: "Item Name",
      field: "itemName",
      hozAlign: "center",
      headerHozAlign: "center",
      frozen: true,
      editable: false,
      width: 150,
    },
    {
      title: "Reports Category",
      field: "reportsCategory.id",
      editor: "list",
      formatter: "lookup",
      formatterParams: reportCategoryIdToNameMap,
      editorParams: {
        values: organization.reportsCategories.map((category) => ({
          label: category.name,
          value: category.id,
        })),
      },
      hozAlign: "center",
      headerHozAlign: "center",
      width: 95,
      editable: canEdit,
    },
    {
      title: "BLDG ID",
      field: "buildingId",
      hozAlign: "center",
      editor: "input",
      editorParams: { verticalNavigation: "table" },
      headerHozAlign: "center",
      editable: canEdit,
      tooltip: false,
      formatter: withPlaceholder("BLDG ID"),
      width: 80,
    },
    {
      title: "Last Installed Date",
      field: "lastInstall",
      hozAlign: "center",
      headerHozAlign: "center",
      editor: customNumberEditor,
      editorParams: { verticalNavigation: "table" },
      tooltip: false,
      editable: canEdit,
      formatter: withPlaceholder("Year"),
      width: 80,
      validator: ["integer", "min:1900"],
    },
    {
      title: "Latest Cost Year",
      field: "latestCostYear",
      hozAlign: "center",
      headerHozAlign: "center",
      editor: customNumberEditor,
      editorParams: { verticalNavigation: "table" },
      editable: canEdit,
      tooltip: false,
      formatter: withPlaceholder("Year"),
      width: 80,
      validator: ["integer", "min:1900"],
    },
    {
      title: "EUL",
      field: "estimatedUsefulLife",
      hozAlign: "center",
      headerHozAlign: "center",
      editor: customNumberEditor,
      editorParams: { verticalNavigation: "table" },
      editable: canEdit,
      tooltip: false,
      width: 50,
      formatter: withPlaceholder("Years"),
      validator: ["integer"],
    },
    {
      title: "RUL",
      field: "remainingUsefulLife",
      mutator: (value, data) => {
        const lastInstall = data.lastInstall;
        const eul = data.estimatedUsefulLife;
        if (typeof lastInstall === "number" && typeof eul === "number")
          return eul - (new Date().getFullYear() - lastInstall);
        return null;
      },
      hozAlign: "center",
      headerHozAlign: "center",
      editable: false,
      tooltip: false,
      width: 50,
      formatter: rulFormatter(withPlaceholder("N/A")),
      validator: ["integer"],
    },
    {
      title: "Yrs to Replace",
      field: "yearsToReplace",
      hozAlign: "center",
      headerHozAlign: "center",
      editor: "list",
      editable: canEdit,
      editorParams: {
        values: [
          { value: 0, label: "0" },
          { value: 1, label: "1" },
          { value: 2, label: "2" },
          { value: 3, label: "3" },
          { value: 4, label: "4" },
          { value: 5, label: "5" },
        ],
        defaultValue: 0,
      },
      tooltip: false,
      width: 80,
      formatter: withPlaceholder("Select Yrs"),
    },
    {
      title: "Quantity",
      field: "itemQuantity",
      hozAlign: "center",
      headerHozAlign: "center",
      editor: customNumberEditor,
      editorParams: { verticalNavigation: "table" },
      editable: canEdit,
      tooltip: false,
      formatter: customMoneyFormatter,
      formatterParams: { symbol: "", placeholder: "Qty", precision: 0 },
      width: 90,
      validator: ["numeric"],
    },
    {
      title: "UoM",
      field: "unitOfMeasure",
      hozAlign: "center",
      headerHozAlign: "center",
      editor: "list",
      editable: canEdit,
      editorParams: {
        values: [
          { value: "EA", label: "Each (EA)" },
          { value: "SF", label: "Square Feet (SF)" },
          { value: "SY", label: "Square Yards (SY)" },
          { value: "SI", label: "Square Inches (SI)" },
          { value: "LF", label: "Linear Feet (LF)" },
          { value: "LD", label: "Linear Yard (LD)" },
          { value: "LB", label: "Pound (LB)" },
          { value: "LS", label: "Lump Sum (LS)" },
          { value: "RL", label: "Roll (RL)" },
          { value: "TN", label: "Ton (TN)" },
          { value: "GL", label: "Gallon (GL)" },
          { value: "BX", label: "Box (BX)" },
        ],
        defaultValue: 0,
      },
      tooltip: false,
      width: 50,
      formatter: withPlaceholder("UoM"),
    },
    {
      title: "Cost Per Unit",
      field: "costPerItem",
      hozAlign: "center",
      headerHozAlign: "center",
      editor: customNumberEditor,
      editorParams: { verticalNavigation: "table" },
      editable: canEdit,
      formatter: customMoneyFormatter,
      formatterParams: { symbol: "$", placeholder: "Cost" },
      tooltip: false,
      width: 120,
      validator: ["numeric"],
      bottomCalcFormatter: () => "TOTAL",
    },
    {
      title: "Total Cost",
      field: "totalCost",
      hozAlign: "center",
      headerHozAlign: "center",
      editable: false,
      formatter: customMoneyFormatter,
      formatterParams: { symbol: "$", placeholder: "N/A" },
      tooltip: false,
      width: 125,
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: { symbol: "$" },
    },
    {
      title: "Upload Photo",
      field: "photoBase64Preview",
      hozAlign: "center",
      headerHozAlign: "center",
      formatter: customReactFormatter(
        <ReserveItemPhotoUpload client={client} canEdit={canEdit} />,
        (cell) => cell.getRow().normalizeHeight()
      ),
      headerSort: false,
      tooltip: false,
      width: 90,
    },
    {
      title: "Upload Document",
      field: "documentBase64Preview",
      hozAlign: "center",
      headerHozAlign: "center",
      formatter: customReactFormatter(
        <ReserveItemDocumentUpload client={client} canEdit={canEdit} />,
        (cell) => cell.getRow().normalizeHeight()
      ),
      headerSort: false,
      tooltip: false,
      width: 80,
    },
    {
      title: "Notes",
      field: "notes",
      headerSort: false,
      formatter: withPlaceholder("Notes"),
      minWidth: 250,
      editor: "textarea",
      tooltip: false,
      editable: canEdit,
    },
  ]);

  const optionsRef = useRef({
    persistence: true,
    persistenceID: "2024-12-08", //change this anytime you change column config above to bust cache
    columnHeaderVertAlign: "middle",
    debugInvalidOptions: false,
  });

  const eventsRef = useRef({
    cellEdited: async (cell) => {
      const field = cell.getField();

      // we will have already saved the photo/document, dont allow another unnecessary network call
      if (field === "documentBase64Preview" || field === "photoBase64Preview")
        return;

      // dont allow another unnecessary network call for read only fields that are set automagically
      if (field === "totalCost" || field === "remainingUsefulLife") return;

      // dont save blanks that never changed
      const val = cell.getValue();
      const oldVal = cell.getOldValue();
      if ((oldVal === null || oldVal === "") && (val === null || val === ""))
        return;

      // Get rid of attributes used only on the page
      const {
        itemType,
        photoBase64Preview,
        documentBase64Preview,
        reportsCategory,
        photoUrl,
        documentUrl,
        ...reserveItem
      } = cell.getData();

      // hack to get reports category saving right
      reserveItem.reportsCategoryId = parseInt(reportsCategory.id);

      // calculate totalCost field automagically
      if (field === "itemQuantity" || field === "costPerItem") {
        const row = cell.getRow();
        const totalCostCell = row.getCell("totalCost");
        const itemQuantity = row.getCell("itemQuantity").getValue();
        const costPerItem = row.getCell("costPerItem").getValue();

        if (
          typeof itemQuantity === "number" &&
          typeof costPerItem === "number"
        ) {
          const totalCost = itemQuantity * costPerItem;
          totalCostCell.setValue(totalCost);
          reserveItem.totalCost = totalCost;
        }
        if (!itemQuantity || !costPerItem) totalCostCell.setValue("");
      }

      // calculate RUL field automagically
      if (field === "lastInstall" || field === "estimatedUsefulLife") {
        const row = cell.getRow();
        const lastInstall = row.getCell("lastInstall").getValue();
        const eul = row.getCell("estimatedUsefulLife").getValue();
        const rulCell = row.getCell("remainingUsefulLife");
        if (typeof lastInstall === "number" && typeof eul === "number") {
          const rul = eul - (new Date().getFullYear() - lastInstall);
          rulCell.setValue(rul);
          reserveItem.remainingUsefulLife = rul;
        } else {
          rulCell.setValue(null);
          reserveItem.remainingUsefulLife = null;
        }
      }

      // convert blanks to nulls for typechecking
      Object.keys(reserveItem).forEach((k) => {
        if (reserveItem[k] === "") reserveItem[k] = null;
      });
      setIsSaving(true);
      let { data } = await updateReserveItem({
        variables: {
          attributes: reserveItem,
        },
      });
      data.updateReserveItem.errors.forEach((error) =>
        toast.error(error.message)
      );
      setIsSaving(false);
    },
  });
  if (filteredReserveItems.length)
    return (
      <ReactTabulator
        options={optionsRef.current}
        events={eventsRef.current}
        className="table-striped table-bordered reserve-items-table sticky-header"
        columns={columnsRef.current}
        data={filteredReserveItems}
        tooltips={true}
      />
    );

  return null;
};

export default ReserveItemsTable;
